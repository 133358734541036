@import "../shared/styles/variables";

.main-page {
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;

  background-color: $col-app-neutral;
}

.student-data-form-container {
  height: 100vh;
  flex: 1 0 320px;
  overflow-y: auto;
  box-shadow: $box-shadow-z3;
  z-index: 2;
}

.hs-programs-container {
  height: 100vh;
  flex: 2 1 50vw;
  max-width: 100vw;
  border: 2px solid $col-app-gray;
}

h1, h2, h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
}
