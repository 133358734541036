@import "../../../shared/styles/variables";

.hs-program-list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;  
  align-items: stretch;
}

.hs-program-list-header {
  box-shadow: $box-shadow-z2;
  z-index: 1;
}

.hs-program-list {
  width: 100%;
  flex: 1 1 80vh;
  overflow-x: auto;
  position: relative;
}
